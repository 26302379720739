import axios from 'axios';

export function apiPut(id: string, key: string, value: any) {
  const postData = {
    [key]: [value],
  };
  axios.post('https://web.556797.xyz/tg-api/put', postData, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => {
    if (response.status === 200) {}
  });
}
